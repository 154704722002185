.videoTitle {
  height: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoTitle h3 {
  
}

iframe {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.modal {
  color: white;
}

.modal-content {
  background-color: transparent !important;
  width: 100%;
  height: 100vh;
}

.modal-full {
  min-width: 100%;
  margin: 0 !important;
}

.modal-content iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.video-container iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

.thumbnail {
  width: 200px;
}

@media (max-width: 800px) {
  .thumbnail {
    width: 210px;
  }
}

.close-video {
  position: absolute;
  right: 20px;
  top: 0;
  font-size: 50px;
  z-index: 999;
  color: white;
  background-color: black;
  width: 60px;
  height: 75px;
  text-align: center;
}

.react-multiple-carousel__arrow::before {
  color: pink !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}

.react-multiple-carousel__arrow--right {
  right: 10px;
}

.react-multiple-carousel__arrow--left {
  left: 10px;
}

.thumbnail:hover,
.close-video:hover {
  cursor: pointer;
}
