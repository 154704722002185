.newsletter-section {
  background: url("/public/art/ice-day-promo-compressed.JPG") center center/cover
    no-repeat;
  background-position-y: 30%;
  color: black;
  height: 100vh;
  position: relative;
  z-index: 100;
}

.newsletter-wrapper {
  width: 500px;
  margin: auto;
}

.newsletter-wrapper form {
  background-color: rgba(0, 0, 0, 0.1);
  width: 500px;
  height: 200px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.newsletter-wrapper form .input-group {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.message-container {
  height: 40px;
}

.newsletter h1 {
  font-size: 4rem;
  color: #ffe543;
  text-shadow: 3px 2px 0 #e64683;
}

.newsletter h2 {
  font-size: 3rem;
  color: #ffe543;
  text-shadow: 3px 2px 0 #e64683;
}

@media (max-width: 600px) {
  .newsletter h1 {
    font-size: 10vw;
  }
  .newsletter-wrapper {
    width: 100%;
  }
}

.newsletter-button {
  background-color: #ffe543 !important;
  box-shadow: 3px 2px 0 #e64683 !important;
  color: #e64683 !important;
  font-size: 1em;
  width: 130px;
  border: none !important;
}

.newsletter-button:hover {
  background-color: #e64683 !important;
  box-shadow: 3px 2px 0 #ffe543 !important;
  color: #ffe543 !important;
}

.newsletter-button:focus {
  outline: none;
}

.newsletter input,
.newsletter input:focus {
  color: white;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  border: none;
  border-bottom: 1px solid white;
  padding: 0;
  font-size: 2em !important;
}

.newsletter label {
  color: white;
  width: 100%;
}

.newsletter .input-group {
  width: 70%;
  margin: auto;
}

::-webkit-input-placeholder {
  /* Edge */
  color: white;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::placeholder {
  color: white;
}
