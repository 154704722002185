.mobile-nav {
  font-size: 1.7rem;
  /* overflow: hidden; */
  background: rgba(255, 105, 206, 0.9);
  color: black;
  position: sticky;
  top: 0;
  z-index: 1001;
}

.overlay {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 9999999;
  transform: translate3d(0, 0, 200px);
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
  overflow: scroll;
}

.overlay-content {
  top: 25%;
  width: 100%;
  margin-top: 30px;
  color: rgba(255, 105, 206, 0.9);
  font-size: 2rem;
}

.nav__socials-button {
  display: block;
  width: 60px;
  padding-top: 15px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  color: rgba(255, 105, 206, 0.9);
}

.nav__socials-button:focus,
.nav__socials-button:hover {
  color: white;
}

.mobile-nav__link,
.mobile-nav__link:focus,
.mobile-nav__link:hover,
.nav__socials-button:focus,
.nav__socials-button:hover {
  color: rgba(255, 105, 206, 0.9);
  text-decoration: none;
}

/************************ DESKTOP NAVBAR******************/
/** STICKY NAVBAR STUFF **/
#navbar {
  -webkit-animation: fade-in 2s; /* Safari 4+ */
  -moz-animation: fade-in 2s; /* Fx 5+ */
  -o-animation: fade-in 2s; /* Opera 12+ */
  animation: fade-in 2s; /* IE 10+, Fx 29+ */
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  overflow: hidden;
  background: rgba(255, 105, 206, 0.7);
  position: sticky;
  top: 0;
  z-index: 1001;
  padding-left: 2%;
}

#navbar a {
  float: left;
  display: block;
  /* color: #f2f2f2; */
  color: black;
  text-align: center;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 1.2rem;
  margin-top: 3px;
}

@media (max-width: 360px) {
  #navbar a {
    padding: 8px 12px;
  }
}

/* #navbar a.active, */
#navbar a:hover {
  color: white;
}

#navbar a i {
  font-size: 1.4rem;
  margin-top: 2px;
}
