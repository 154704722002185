.listenNow {
  width: 260px;
  /* box-shadow: 0 0px 8px 10px rgba(255, 255, 255, 0.2); */
  display: inline-block;
  margin-bottom: 50px;
}

.listenNow-art,
.listenNow-art > img {
  width: 260px;
  height: 260px;
}

.listenNow-toggler {
  background-color: #171717;
  /* background-color: black; */
  color: #fc3eaa;
  padding-top: 25px;
  padding-bottom: 25px;
  cursor: pointer;
}

.listenNow-toggler:focus {
  border: 2px solid white;
}

.listenNow-toggler h1 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
  display: inline;
  font-size: 30px;
}

.presave-link,
.presave-link:hover {
  text-decoration: none;
}

.presave-link h1 {
  margin-left: 28px;
}

.expand-arrow {
  width: 25px;
  margin-left: 70px;
  margin-bottom: 10px;
  filter: invert(57%) sepia(91%) saturate(4983%) hue-rotate(301deg)
    brightness(98%) contrast(102%);
}

.spotify-logo {
  height: 38px;
}

.apple-logo {
  height: 38px;
}

.youtube-logo {
  width: 90px;
}

.soundcloud-logo {
  width: 130px;
}

.listenNow-links-container {
  text-align: center;
}

.listenNow-link {
  background-color: white;
  height: 60px;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.ReactCollapse--collapse {
  max-width: 800px;
  transition: height 500ms;
}
