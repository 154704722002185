@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
.bio {
  font-family: "Open Sans", sans-serif;
  -webkit-animation: fade-in 2s; /* Safari 4+ */
  -moz-animation: fade-in 2s; /* Fx 5+ */
  -o-animation: fade-in 2s; /* Opera 12+ */
  animation: fade-in 2s; /* IE 10+, Fx 29+ */
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.bio-text {
  background-color: black;
}

.bio img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.thumbnails {
  background-color: rgba(0, 0, 0, 0);
}

.thumbnails img {
  max-width: 30%;
  padding: 15px 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .thumbnails {
    display: none !important;
  }
}

.big-img {
  max-width: 250px;
}

.bio p {
  font-size: 0.9rem;
}
