/* BASE STYLES */
@import url("https://fonts.googleapis.com/css2?family=VT323");

.listenNow-art:hover {
  width: 100px;
}

body {
  font-family: "VT323", monospace !important;
  color: white;
  background-color: black !important;
  background-image: url("/public/background-50.gif");
}

a {
  color: lightblue;
}

.App {
  transition: height 999999s;
}

.text-black {
  color: black;
}

.bg-black {
  background-color: black;
}

/* LAYOUT STYLES */
.h-vh {
  height: 100vh;
}

/* MODULE STYLES */
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.glow {
  font-size: 4rem;
  animation: glow 2s ease-in-out infinite alternate;
  -webkit-animation: glow 2s ease-in-out infinite alternate;
  -moz-animation: glow 2s ease-in-out infinite alternate;
  text-align: center;
}

@keyframes glow {
  from {
    text-shadow: 5px 5px 50px #a92d8e;
  }
  to {
    text-shadow: 5px 5px 50px #ff0852, 5px 5px 10px #ff0852;
  }
}
